// npm
import { Box, BoxProps, Grid } from '@mui/material'
import React, { memo } from 'react'
// src

import CmsLinkButton, { buttonLinkProps } from '@atoms/buttons/CmsLinkButton'
import Motif from '@atoms/svg/Motif'
import MainHeading, { MainHeadingProps } from '@molecules/headings/MainHeading'
import Subtitle, { SubtitleProps } from '@molecules/headings/Subtitle'
import { SectionTextVariants } from 'constants/section-text-variants'

export interface SectionTextProps extends SectionTextVariants {
  subtitleProps: SubtitleProps
  mainHeadingProps: MainHeadingProps
  textBoxProps?: BoxProps
  text?: string | undefined
  button: Queries.DatoCmsButton
  buttonLinkProps?: buttonLinkProps
  buttonContainerProps?: BoxProps
  motif?: Boolean
}

const SectionText = ({
  sectionTextVariant,
  subtitleProps,
  mainHeadingProps,
  textBoxProps,
  text,
  buttonLinkProps,
  buttonContainerProps,
  button,
  motif,
}: SectionTextProps) => {
  if (sectionTextVariant) {
    switch (sectionTextVariant) {
      case 'centered':
        textBoxProps = {
          ...textBoxProps,
          textAlign: 'center',
          pb: 2,
        }
        subtitleProps.typographyProps = {
          ...subtitleProps.typographyProps,
          mb: 2,
        }
        break
      case 'indented-text':
        const px = {
          px: {
            xs: 0,
            sm: 4,
            md: 6,
            lg: 8,
          },
        }

        mainHeadingProps.typographyProps = {
          ...mainHeadingProps.typographyProps,
          mb: {
            xs: 0,
            lg: 1,
          },
        }
        subtitleProps = {
          ...subtitleProps,
          flexProps: {
            ml: {
              xs: -3,
              sm: 0,
            },
          },
        }
        textBoxProps = {
          ...textBoxProps,
          ...px,
        }
        buttonContainerProps = {
          ...px,
        }
        break
    }
  }

  return (
    <>
      {sectionTextVariant === 'centered' ? (
        <>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={9} lg={7} xl={6} textAlign="center">
              <Subtitle {...subtitleProps} />
              <MainHeading {...mainHeadingProps} />
            </Grid>
          </Grid>

          {text && (
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={11} sm={8} lg={6} xl={4}>
                {text && (
                  <Box
                    {...textBoxProps}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                )}
                {motif && <Motif />}
                {button && (
                  <Box mt={4} {...buttonContainerProps} textAlign="center">
                    <CmsLinkButton
                      button={button}
                      buttonLinkProps={buttonLinkProps}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <>
          <Subtitle {...subtitleProps} />
          <MainHeading {...mainHeadingProps} />
          {text && (
            <Box {...textBoxProps} dangerouslySetInnerHTML={{ __html: text }} />
          )}
          {button && (
            <Box mt={4} {...buttonContainerProps}>
              <CmsLinkButton
                button={button}
                buttonLinkProps={buttonLinkProps}
              />
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default memo(SectionText)
