// npm
import React, { memo } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Box, Container, Grid, styled, Typography } from '@mui/material'
// src
import Flex from '@atoms/layout/Flex'
import SectionText, { SectionTextProps } from '@molecules/text/SectionText'
import { SectionTextVariants } from 'constants/section-text-variants'
import Plx from 'react-plx'

interface Props extends SectionTextVariants {
  image: IGatsbyImageData
  keyId: string
  subtitle: string
  mainHeading: string
  text: string
  button: Queries.DatoCmsButton
  backgroundText: string
}

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

const ContentSectionTextImage = ({
  image,
  keyId,
  subtitle,
  mainHeading,
  button,
  text,
  backgroundText,
}: Props) => {
  let sectionTextProps: SectionTextProps = {
    mainHeadingProps: {
      typographyProps: {
        variant: 'h2',
      },
      lineBreaksProps: {
        keyId: keyId,
        text: mainHeading,

        breakpoint: 'xs',
      },
    },
    subtitleProps: {
      color: 'primary.main',
      text: subtitle,
      borderType: 'left',
      typographyProps: {
        component: 'h2',
      },
    },
    text: text,
    textBoxProps: {
      fontSize: '1.8rem',
      lineHeight: '1.5',
    },
    button: button,
    buttonLinkProps: {
      colorVariant: 'primary-text',
    },
  }

  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '120vh',
      properties: [
        {
          startValue: 40,
          endValue: -70,
          property: 'translateX',
          unit: '%',
        },
      ],
    },
  ]

  return (
    <Box
      pt={{ xs: 0, md: 10 }}
      pb={{ xs: 0, md: 6, lg: 13 }}
      sx={{
        position: 'relative',
        backgroundColor: 'primary.light',
        overflow: 'hidden',
      }}
    >
      <StyledContainer maxWidth="xl">
        <Box position="absolute" display={{ xs: 'none', md: 'block' }}>
          <Plx parallaxData={parallaxData}>
            <Typography color="primary.main" variant="backgroundText">
              {backgroundText}
            </Typography>
          </Plx>
        </Box>

        <Grid container spacing={0} sx={{ pt: { xs: 0, md: 20, xl: 6 } }}>
          <Grid
            item
            md={6}
            xl={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              order: {
                xs: 2,
                md: 1,
              },
            }}
          >
            <Box
              bgcolor="white"
              p={{ xs: 4, sm: 6, md: 8, lg: 10, xl: 12 }}
              mt={{ xs: 0, md: 5, lg: 0 }}
              sx={{
                position: 'relative',
                zIndex: 10,
              }}
            >
              <SectionText {...sectionTextProps} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            xl={6}
            sx={{
              order: {
                xs: 1,
                md: 2,
              },
            }}
          >
            <Flex fullWidth>
              <Box
                sx={{
                  float: 'left',
                  position: 'relative',
                  zIndex: 2,
                  mx: {
                    md: -4,
                    xl: -6,
                  },
                  pb: {
                    md: 10,
                    xl: 10,
                  },
                  order: {
                    xs: 1,
                    lg: 2,
                  },
                }}
              >
                <GatsbyImage image={image} alt="" />
              </Box>
            </Flex>
          </Grid>
        </Grid>
      </StyledContainer>
    </Box>
  )
}

export default memo(ContentSectionTextImage)
